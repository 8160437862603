<template>
  <div v-if="variant != -1" class="product-notify">
    <h3>Join the waitlist</h3>
    <p>We'll message you when this item is back in stock.</p>
    <form v-if="message == ''" class="form-content" method="get" @submit.prevent="submit($event)">
      <input
        v-model="email"
        type="email"
        name="email"
        placeholder="Email Address"
        size="10"
        maxlength="50"
        required="true"
      />
      <button>
        <SvgArrowNewsletter />
      </button>
    </form>
    <div v-else class="message" v-html="message"></div>
  </div>
</template>

<script>
import axios from 'axios'
import SvgArrowNewsletter from '../svg/SvgArrowNewsletter.vue'

const KLAVIYO_API_URL = 'https://a.klaviyo.com/client/back-in-stock-subscriptions/'

export default {
  components: { SvgArrowNewsletter },
  props: {
    product: Object,
    variant: [Number, Object],
  },
  data() {
    return {
      email: '',
      message: '',
      public_api_key: 'tVvjMG',
    }
  },
  methods: {
    async submit($evt) {
      try {
        const response = await axios({
          method: 'post',
          url: `${KLAVIYO_API_URL}?company_id=${this.public_api_key}`,
          data: {
            data: {
              type: 'back-in-stock-subscription',
              attributes: {
                channels: ['EMAIL'],
                profile: {
                  data: {
                    type: 'profile',
                    attributes: {
                      email: this.email,
                    },
                  },
                },
              },
              relationships: {
                variant: {
                  data: {
                    type: 'catalog-variant',
                    id: `$shopify:::$default:::${this.variant.id}`,
                  },
                },
              },
            },
          },
          headers: {
            'Content-Type': 'application/json',
            revision: '2024-07-15',
          },
        })

        if (response.status === 202) {
          this.message = "You're in! We'll let you know when it's back."
          this.email = ''
        } else {
          throw new Error('Subscription failed')
        }
      } catch (e) {
        console.error('Error:', e)
        this.message = 'An error occurred. Please try again later.'
      }
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-notify {
  max-width: 100%;
  padding-bottom: 30px;
  h3 {
    @include cx-uc;
  }
  > p {
    padding-top: 0.5em;
    padding-bottom: 5px;
    color: $midgrey;
  }

  .form-content {
    position: relative;
    width: 100%;
    border-bottom: 1px solid $dark;
    input {
      width: 100%;
      &::placeholder {
        color: $dark;
      }
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 15px;
    }
  }
}
</style>
