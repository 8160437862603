<template>
  <img :src="getUrl" />
</template>

<script>
import PreloadMixin from '~/mixins/preload'

export default {
  mixins: [PreloadMixin],
  props: {
    src: String,
    ratio: String,
    simple: { default: false },
  },
  data() {
    return {
      loaded: false,
      width: false,
    }
  },
  computed: {
    getStyle() {
      const width = this.getWidth
      const height = this.getHeight

      return {
        width: `${width}px`,
        height: `${height}px`,
      }
    },
    getWidth() {
      let width = 200

      if (this.width) {
        width = Math.round(Math.ceil((this.width * 2) / 50) * 50)
        if (width > 500) width = 500
      }

      return width
    },
    getHeight() {
      let height = ''
      if (this.ratio) {
        const numbers = this.ratio.split('x')
        const ratio = numbers[1] / numbers[0]
        height = Math.floor(this.getWidth * ratio)
      }

      return height
    },
    getSrc() {
      return this.formatUrl(this.src, this.getWidth, this.getHeight)
    },
    getUrl() {
      if (!this.simple) {
        return this.formatUrl(this.src, 1200)
      }
      if (!this.loaded)
        return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${this.getWidth} ${this.getHeight}'%3E%3C/svg%3E`
      return this.getSrc
    },
  },
  mounted: async function () {
    this.loaded = true

    await this.preloadImage(this.getSrc)

    this.$nextTick(() => {
      // if (!!process.server) return;
      window.addEventListener('resize', this.updateWidth())
    })
  },
  methods: {
    formatUrl(src, width, height = '') {
      let url = ''

      // Handle cloudinary urls
      if (src.startsWith('https://cdn.accentuate.io')) {
        url = `${src}&transform=cover=${this.ratio.replace('x', ':')}/resize=${width}`
      } else {
        url = src.split('?')[0]
        url = `${url}?${width}x${height}`
      }

      return url
    },
    updateWidth() {
      if (!!this.width) return this.width
      this.width = this.$el.clientWidth
    },
  },
}
</script>

<style lang="scss" scoped>
//@import '~styles/base.scss';
img {
  //background: var(--color-red);
  width: 100%;
}
</style>
