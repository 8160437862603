<template>
  <div v-if="getSlides.length > 0" class="product-collection">
    <h2 class="product-collection__heading">
      From Our<br />
      Community
    </h2>
    <GlobalCarousel
      :desktop-options="{
        slidesPerView: 4,
      }"
      :slides="getSlides"
      :slide-component="'GlobalSlideCommunity'"
    />
  </div>
</template>

<script>
import GlobalCarousel from '../global/GlobalCarousel.vue'

export default {
  components: { GlobalCarousel },
  props: {
    product: Object,
  },
  computed: {
    getSlides() {
      const feed = this.product.metafields.feed
      if (!feed.photo) return []

      const photos = feed.photo
        .map((photo, index) => {
          return {
            image: photo[0],
            caption: feed.caption[index],
            link: feed.link[index],
          }
        })
        .filter((v) => v.image && v.caption && v.link)

      return photos
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-collection {
  &__heading {
    @include sans;
    @include cx-uc;
    @include cx-l;
    display: flex;

    @include respond-to('m+') {
      @include wrapper;
      margin-bottom: 60px;

      &:before {
        display: block;
        content: ' ';
        width: columns(4);
      }
    }

    @include respond-to(m) {
      margin-bottom: 40px;
    }
  }

  @include respond-to('m') {
    @include wrapper;
  }
}
</style>
